import React, { useContext, useEffect, useState } from 'react';
import ComponentCard from '../../components/ComponentCard';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import user1 from '../../assets/images/users/user1.jpg';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast';
import { Context } from '../../jotai';
import { deleteBanner, getBanner } from '../../api/banner';
import useBannerJotai from '../../jotai/banner';
import PaginationItems from '../../helpers/paginations';
import Loader from '../../layouts/loader/Loader';

const BannerList = () => {

    const navigate = useNavigate()
    const [banner, setBanner] = useState([])
    const { setSingleBannerJotai } = useBannerJotai()
    const [context, setContext] = useContext(Context)

    const [modal, setModal] = useState(false);
    const [delete_id, set_delete_id] = useState();

    const toggle = () => setModal(!modal);

    const getData = async () => {
        setBanner([])
        const query = {
            page_size: pageLimit,
            page: pageNumber,
            restaurant_id: context?.user?.role !== 'admin' ? context?.user?.id : ''
        }
        const menuData = await getBanner(query)
        setBanner(menuData?.banner)
    }
    const [pageNumber, setPageNumber] = useState(1);
    const pageLimit = 5;

    useEffect(() => {
        getData()
    }, [pageNumber])

    const handleSingleData = (data) => {
        setSingleBannerJotai(data)
        navigate(`/dashboard/banner/create`)
    }

    const handleDelete = async (id) => {
        const res = await deleteBanner(id)
        if (res?.banner?.deletedCount) {
            toast.error('Banner Delete Successfully!')
            getData()
            toggle()
            setSingleBannerJotai()
        }
    }
    const handleSureDelete = (id) => {
        set_delete_id(id)
        toggle()
    }
    return (
        <>
            <ComponentCard
                title="Banner Listing"
                subtitle={"Overview of the Banner"}
            >
                <div>
                   
                        <Table className="no-wrap align-middle" responsive borderless>
                            <thead>
                                <tr>
                                <th className='px-4'>S.NO.</th>
                                    <th className='px-4'>Banner Info</th>
                                    <th className='px-4'>Status</th>
                                    <th className='px-4'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {banner?.length ? banner?.map((tdata,index) => (
                                    <tr key={tdata.name} className="border-top">
                                        <td>{pageLimit *(pageNumber-1)+ index+1}.</td>
                                        <td>
                                            <div className="d-flex align-items-center p-2">
                                                <img
                                                    src={tdata.image ? tdata.image : user1}
                                                    style={{ borderRadius: "13px" }}
                                                    alt="avatar"
                                                    width="200"
                                                    height="95"
                                                />
                                                <div className="ms-3">
                                                    <h5 className="mb-0 fw-medium">{tdata.name}</h5>
                                                    {/* <span className="text-muted">{tdata.email}</span> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {tdata.active ? (
                                                <span className="p-2 bg-success rounded-circle d-inline-block ms-3" />
                                            ) :
                                                <span className="p-2 bg-danger rounded-circle d-inline-block ms-3" />
                                            }
                                        </td>
                                     

                                        <td>
                                            <tr>
                                                <Button color="primary" onClick={() => handleSingleData(tdata)} ><Icon.Edit /> Edit</Button>
                                                &nbsp;&nbsp;<Button color="danger" onClick={() => handleSureDelete(tdata.id)}><Icon.Trash /> Delete</Button></tr>

                                        </td>
                                    </tr>
                                )):<Loader />}
                            </tbody>
                        </Table> 
                </div>
                <PaginationItems
                        nextBtn={pageLimit > banner?.length}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                />
            </ComponentCard>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Restaurant Delete</ModalHeader>
                <ModalBody>
                    Are You Sure!.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        No
                    </Button>
                    <Button color="primary" onClick={() => handleDelete(delete_id)}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default BannerList;
