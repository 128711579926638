import { APIClient } from "./apiCore";
import * as url from "./urls";
const api = new APIClient();

//Restaurant
export const getRestaurants = (data) => api.get(url.RESTAURANT, { params: { q:data?.q, page:data?.page,pageSize:data?.page_size }});
export const deleteRestaurant = (id) => {
    return api.delete(url.RESTAURANT, { params: { id } });
}
export const updateRestaurant = (data) => api.update(url.RESTAURANT, data);
export const postCreateRestaurant = (data) => api.create(url.RESTAURANT, data);

//Menu
export const getMenus = (data) => {
    return api.get(url.RESTAURANT_MENU, { params: { page:data?.page,pageSize:data?.page_size, restaurant_id:data?.restaurant_id } });
}

export const deleteMenu = (id) => {
    return api.delete(url.RESTAURANT_MENU, { params: { id } });
}

export const updateMenu = (data) => api.update(url.RESTAURANT_MENU, data);
export const postCreateMenu = (data) => api.create(url.RESTAURANT_MENU, data);
