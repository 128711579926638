import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import PrivateLayout from '../layouts/PrivateLayout';
import MenuList from '../views/menu/list';
import MenuForm from '../views/menu/form';
import SettingForm from '../views/setting/form';
import CategoryList from '../views/category/list';
import CategoryForm from '../views/category/form';
import OrderList from '../views/order/list';
import BlankLayout from '../layouts/BlankLayout';
import Error from '../views/auth/Error';
import RegisterFormik from '../views/auth/RegisterFormik';
import LoginFormik from '../views/auth/LoginFormik';
import Maintanance from '../views/auth/Maintanance';
import LockScreen from '../views/auth/LockScreen';
import RecoverPassword from '../views/auth/RecoverPassword';
const Minimal = Loadable(lazy(() => import('../views/dashboard/Minimal')));


const RestaurantRoute = [
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', name: 'Minimal', exact: true, element: <Minimal /> },
      { path: '/dashboard/category/list', name: 'User', exact: true, element: <CategoryList /> },
      { path: '/dashboard/category/create', name: 'User', exact: true, element: <CategoryForm /> },
      { path: '/dashboard/menu/list', name: 'User', exact: true, element: <MenuList /> },
      { path: '/dashboard/menu/create', name: 'User', exact: true, element: <MenuForm /> },
      { path: '/dashboard/order/recent-order', name: 'Order', exact: true, element: <OrderList /> },
      { path: '/dashboard/setting', name: 'User', exact: true, element: <SettingForm /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export default RestaurantRoute;
