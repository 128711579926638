import { APIClient } from "./apiCore";
import * as url from "./urls";
const api = new APIClient();

//Menu
export const getNotification = (data) => {
    return api.get(url.NOTIFICATION, { params: {page:data?.page,pageSize:data?.page_size } });
}
export const deleteNotification = (id) => {
    return api.delete(url.NOTIFICATION, { params: { id } });
}
export const updateNotification = (data) => api.update(url.NOTIFICATION, data);
export const postCreateNotification = (data) => api.create(url.NOTIFICATION, data);
