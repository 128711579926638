import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as LogoDarkIcon } from '../../assets/images/logos/elite-dark-icon.svg';
import LogoDarkText from '../../assets/images/logos/logo.png';
import { ReactComponent as LogoWhiteIcon } from '../../assets/images/logos/elite-white-icon.svg';
import LogoWhiteText from '../../assets/images/logos/logo.png';

const AuthLogo = () => {
  const isDarkMode = useSelector((state) => state.customizer.isDark);

  return (
    <div className="p-4 d-flex justify-content-center gap-2">
      {isDarkMode !== false ? (
        <>
          {/* <LogoWhiteIcon /> */}
          <img src={LogoWhiteText} width="300px" className="d-none d-lg-block" alt='logo-text' />
        </>
      ) : (
        <>
          {/* <LogoDarkIcon /> */}
          <img src={LogoDarkText} width="300px" className="d-none d-lg-block" alt='logo-text' />
        </>
      )}
    </div>
  );
};

export default AuthLogo;
