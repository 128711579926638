/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import { Provider, atom, useAtom } from 'jotai';
import { verifyToken } from './api/auth';
import authHeader from './helpers/jwt-token-access/auth-token-header';
import { toast } from 'wc-toast';
import { Context } from './jotai';
import RestaurantRoute from './routes/restaurantRoute';
import ThemeRoutes from './routes/AdminRoute';
import WithOutAuthRoute from './routes/loginRoute';

export const LoginUser = atom()


const App = () => {
  const navigate = useNavigate()
  const [context, setContext] = useState();
  const withOutAuthRoute = useRoutes(WithOutAuthRoute)
  const routing = useRoutes(ThemeRoutes);
  const restaurantRouting = useRoutes(RestaurantRoute)
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  const [loginUserJotai, setLoginUserJotai] = useAtom(LoginUser)
  
  const verifyTokenLogin = async () =>{
    try{
      
      const response = await  verifyToken()
      if(response?.user?.active){
        setLoginUserJotai(response?.user)
        console.log(response,'ddddddddddddddddd')
        setContext(response)
      }else{
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        toast.error('You are going to logout!')
        setTimeout(function() {window.location.href = "/auth/login"}, 8000);
      }
    }catch(err){
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      toast.error('You are going to logout!')
      setTimeout(function() {window.location.href = "/auth/login"}, 8000);
    }
  }

  useEffect(()=>{
    const auth = authHeader();
    auth.withOutBearertoken && verifyTokenLogin()
  },[])

  return (
    
    <Provider>
      <Context.Provider value={[context, setContext]} >
      <Suspense fallback={""}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <wc-toast></wc-toast>
        <ThemeSelector />
        { localStorage.getItem('userRole') === 'admin' ? routing : restaurantRouting}
      </div>
    </Suspense>
    </Context.Provider>
    </Provider>
  );
};

export default App;
