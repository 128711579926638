import { APIClient } from "./apiCore";
import * as url from "./urls";
const api = new APIClient();

//Menu
export const getCategory = (data) => {
    return api.get(url.CATEGORY, { params: {page:data?.page,pageSize:data?.page_size, restaurant_id:data?.restaurant_id } });
}
export const deleteCategory = (id) => {
    return api.delete(url.CATEGORY, { params: { id } });
}
export const updateCategory = (data) => api.update(url.CATEGORY, data);
export const postCreateCategory = (data) => api.create(url.CATEGORY, data);
