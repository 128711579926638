import { APIClient } from "./apiCore";
import * as url from "./urls";
const api = new APIClient();

//Menu
export const getBanner = (data) => {
    return api.get(url.BANNER, { params: {page:data?.page,pageSize:data?.page_size } });
}
export const deleteBanner = (id) => {
    return api.delete(url.BANNER, { params: { id } });
}
export const updateBanner = (data) => api.update(url.BANNER, data);
export const postCreateBanner = (data) => api.create(url.BANNER, data);
