import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-validation/build/form';
import ComponentCard from '../../components/ComponentCard';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';
import { postCreateMenu, postCreateRestaurant, updateMenu, updateRestaurant } from '../../api/restaurant';
import { uploadImage } from '../../api/upload';
import useRestaurantJotai from '../../jotai/restaurant';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { getUsers } from '../../api/users';
import { Context } from '../../jotai';
import { postCreateCategory, updateCategory } from '../../api/category';
import useCategoryJotai from '../../jotai/category';

const CategoryForm = () => {

    const navigate = useNavigate()
    const { singleCategoryJotai, setSingleCategoryJotai } = useCategoryJotai()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    const [active, setActive] = useState(singleCategoryJotai?.active ? true : false)
    const [context, setContext] = useContext(Context)

    const onSubmit = async (data) => {
        data.active = active
        data.image = file
        data.restaurant_id = context?.user?.id
        const result = await postCreateCategory(data)
        if (result?.category) {
            setSingleCategoryJotai()
            setActive()
            setFile()
            toast.success('Cayegory Created Successfully!')
            navigate("/dashboard/category/list")
        } else {
            toast.error(result.message);
        }
    };

    const onUpdate = async (data) => {
        data.active = active
        data.id = singleCategoryJotai.id
        data.image = file
        const result = await updateCategory(data)
        if (result?.category?.acknowledged) {
            setSingleCategoryJotai()
            setActive()
            setFile()
            toast.success('Category Updated Successfully!')
            navigate("/dashboard/category/list")
        } else {
            toast.error(result.message);
        }
    };

    const handleFileInput = async (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            try {
                const response = await uploadImage(formData, 'category');
                if (response.url) {
                    setFile(response.url)
                    toast.success('Image Uploaded Successfully!!')
                } else {
                    toast.error('error in uploading image!')
                }
            } catch (error) {
                console.warn('error: ', error);
            }
        }
    };


    return (
        <>
            <Row>
                <Col sm="12">
                    <ComponentCard title={singleCategoryJotai?.id ? "Update Category" : "Create Category"}>
                        <Form
                            onSubmit={handleSubmit(singleCategoryJotai?.id ? onUpdate : onSubmit)}
                        >
                            <FormGroup>
                                <Label className="control-Label" htmlFor="item_name">
                                Category Name *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('name', { required: true, pattern: /^(?![\s-])[\w\s-]+$/ })}
                                        className="form-control"
                                        defaultValue={singleCategoryJotai?.name || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.name && 'Menu Name is required.'}</span>
                            </FormGroup>
                            <FormGroup className="mb-1">
                                <Label className="control-Label" htmlFor="image">
                                    Image *
                                </Label>
                                <div className="custom-file">
                                    <Input type="file"
                                        {...register('image')}
                                        onChange={handleFileInput}
                                        className="custom-file-input" id="customFile1" />
                                </div>
                                <span className="text-danger">{errors.image && 'Image is required.'}</span>

                            </FormGroup>
                            {
                                file ?
                                    <FormGroup className="mb-1">
                                        <Label className="control-Label" htmlFor="image">
                                            Image View
                                        </Label>
                                        <div className="custom-file">
                                            <img
                                                src={file}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.image && 'Image is required.'}</span>

                                    </FormGroup> : null
                            }
                            <FormGroup>
                                <Switch onText="Active" onChange={() => setActive(active ? false : true)} offText="Deactive" defaultValue={active} />
                            </FormGroup>
                            <FormGroup>
                                {singleCategoryJotai?.id ?
                                    <Button className="button btn-info" type="submit">
                                        Update
                                    </Button>
                                    :
                                    <Button className="button btn-info" type="submit">
                                        Submit
                                    </Button>
                                }
                            </FormGroup>
                        </Form>
                    </ComponentCard>
                </Col>
            </Row>
        </>
    );
};

export default CategoryForm;
