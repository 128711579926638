import React, { useState } from 'react';
import { Row, Col, Button, FormGroup, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-validation/build/form';

import ComponentCard from '../../components/ComponentCard';
import { postCreateUser, updateUser } from '../../api/auth';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';
import useUserJotai from '../../jotai/user';
import Switch from 'react-bootstrap-switch';

const UserForm = () => {

    const navigate = useNavigate()
    const { singleUserJotai, setSingleJotai } = useUserJotai()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [active, setActive] = useState(false)

    const onSubmit = async (data) => {
        data.active = active
        const result = await postCreateUser(data)
        if (result?.success) {
            setSingleJotai()
            toast.success('User Created Successfully!')
            navigate("/dashboard/user/list")
        } else {
            toast.error(result.message);
        }
    };

    const onUpdate = async (data) => {
        data.id = singleUserJotai.id
        const result = await updateUser(data)
        if (result?.users?.acknowledged) {
            setSingleJotai()
            toast.success('User Updated Successfully!')
            navigate("/dashboard/user/list")
        } else {
            toast.error(result.message);
        }
    };

    return (
        <>
            <Row>
                <Col sm="12">
                    <ComponentCard title={singleUserJotai?.id ? "Update User" : "Create User"}>
                        <Form
                            onSubmit={handleSubmit(singleUserJotai?.id ? onUpdate : onSubmit)}
                        >
                            <FormGroup>
                                <Label className="control-Label" htmlFor="firstname">
                                    Name *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('name', { required: true,pattern: /^(?![\s-])[\w\s-]+$/ })}
                                        className="form-control"
                                        defaultValue={singleUserJotai?.name || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.name && 'Name is required.'}</span>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-Label" htmlFor="phone_number">
                                    Phone Number *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('phone_number', { required: true, maxLength: 11, minLength: 8 })}
                                        className="form-control"
                                        defaultValue={singleUserJotai?.phone_number || ''}
                                    />
                                </div>
                                <span className="text-danger">
                                    {errors.phone_number && 'Enter a Valid phone number.'}
                                </span>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-Label" htmlFor="email">
                                    Email *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                                        className="form-control"
                                        defaultValue={singleUserJotai?.email || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.email && 'Email is required.'}</span>
                            </FormGroup>
                            {
                                !singleUserJotai?.id &&
                            <FormGroup>
                                <Label className="control-Label" htmlFor="password">
                                    Password *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('password', { required: true })}
                                        className="form-control"
                                        defaultValue={singleUserJotai?.password || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.password && 'Password is required.'}</span>
                            </FormGroup>
                            }
                            <FormGroup>
                                <Label className="control-Label" htmlFor="role">
                                    User Type *
                                </Label>
                                <div className="mb-2">
                                    <select
                                        className="form-control"
                                        {...register('role', { required: true })}
                                        defaultValue={singleUserJotai?.role || ''}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </select>
                                </div>
                                <span className="text-danger">{errors.role && 'Please select value.'}</span>
                            </FormGroup>
                            <FormGroup>
                                <Switch onText="Active" onChange={() => setActive(singleUserJotai?.active ? false : true)} offText="Deactive" defaultValue={singleUserJotai?.active} />
                            </FormGroup>
                            <FormGroup>
                                {singleUserJotai?.id ?
                                    <Button className="button btn-info" type="submit">
                                        Update
                                    </Button>
                                    :
                                    <Button className="button btn-info" type="submit">
                                        Submit
                                    </Button>
                                }
                            </FormGroup>
                        </Form>
                    </ComponentCard>
                </Col>
            </Row>
        </>
    );
};

export default UserForm;
