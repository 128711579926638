import React, { useEffect } from "react";
import usePagination from "./usePagination.js";
import PaginationComponent from "../../views/ui/Pagination.js";
import { Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import ComponentCard from "../../components/ComponentCard.js";

const PaginationItems = (props) => {
    //   const { pageNumber, changePage, pageData, nextPage, previousPage } =
    //     usePagination(props.items, props.pageLimit, props.pageNumber, props.setPageNumber);
    //     // pageNumber={pageNumber}
    //     // setPageNumber={setPageNumber}
    //     console.log(pageData,"pageDatapageData")
    //   useEffect(() => {
    //     props.setPageItems(pageData);
    //   }, [props.pageNumber]);


    const { pageNumber, setPageNumber, nextBtn } = props;
    const handleChangePgae = (type) => {
        if (type === 'prev') {
            const page = pageNumber - 1
            setPageNumber(page === 0 ? 1 : page)
        } else if (type === 'next') {
            setPageNumber(pageNumber + 1)
        }
    }
    const changePage = (number) =>{
        setPageNumber(number)
    }
    return (
        <div className="d-flex align-items-right">
            {/* <b onClick={previousPage}>Prev</b>
      <input
        value={pageNumber}
        onChange={(e) => {
          changePage(e.target.valueAsNumber);
        }}
        type="number"
      />
      <b onClick={nextPage}>Next</b> */}



            <Col xs="12" md="12">
                <div className="align-middle">
                <Pagination aria-label="Page navigation example">
                    <PaginationItem>
                        <PaginationLink previous onClick={() => handleChangePgae('prev')} />
                    </PaginationItem>

                    {/* <PaginationItem>
                        <PaginationLink onClick={() => changePage(1)} >1</PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink onClick={() => changePage(2)} >2</PaginationLink>
                    </PaginationItem> */}
                    <PaginationItem disabled={nextBtn}>
                        <PaginationLink  onClick={() => handleChangePgae('next')} next />
                    </PaginationItem>
                </Pagination>
                </div>
            </Col>
        </div>
    );
};

export default PaginationItems;