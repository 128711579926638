import * as Icon from 'react-feather';

const SidebarDataRestaurant = [
  { caption: 'Personal' },
  {
    title: 'Dashboard',
    href: '/dashboard',
    id: 1,
    suffix: '1',
    suffixColor: 'bg-cyan rounded-pill text-dark-white',
    icon: <Icon.Home />,
    collapisble: true,
    children: [
      {
        title: 'Food',
        href: '/dashboard',
        icon: <Icon.Disc />,
        id: 1.1,
        collapisble: false,
      },
    ],
  },
  {
    title: 'Category',
    href: '/category',
    id: 1,
    suffix: '2',
    suffixColor: 'bg-cyan rounded-pill text-dark-white',
    icon: <Icon.Menu />,
    collapisble: true,
    children: [
      {
        title: 'Category List',
        href: '/dashboard/category/list',
        icon: <Icon.Disc />,
        id: 1.1,
        collapisble: false,
      },
      {
        title: 'Create Category',
        href: '/dashboard/category/create',
        icon: <Icon.Disc />,
        id: 1.1,
        collapisble: false,
      },
    ],
  },
  {
    title: 'Menu',
    href: '/menu',
    id: 1,
    suffix: '2',
    suffixColor: 'bg-cyan rounded-pill text-dark-white',
    icon: <Icon.Coffee />,
    collapisble: true,
    children: [
      {
        title: 'Menu List',
        href: '/dashboard/menu/list',
        icon: <Icon.Disc />,
        id: 1.1,
        collapisble: false,
      },
      {
        title: 'Create Menu',
        href: '/dashboard/menu/create',
        icon: <Icon.Disc />,
        id: 1.1,
        collapisble: false,
      },
    ],
  },
  {
    title: 'Order',
    href: '/order',
    id: 1,
    suffix: '2',
    suffixColor: 'bg-cyan rounded-pill text-dark-white',
    icon: <Icon.ShoppingCart />,
    collapisble: true,
    children: [
      {
        title: 'Recent Order List',
        href: '/dashboard/order/recent-order',
        icon: <Icon.Disc />,
        id: 1.1,
        collapisble: false,
      },

    ],
  },
  {
    title: 'Setting',
    href: '/setting',
    id: 1,
    suffix: '1',
    suffixColor: 'bg-cyan rounded-pill text-dark-white',
    icon: <Icon.Settings />,
    collapisble: true,
    children: [
      {
        title: 'Setting',
        href: '/dashboard/setting',
        icon: <Icon.Disc />,
        id: 1.1,
        collapisble: false,
      },

    ],
  },
  
];

export default SidebarDataRestaurant;
