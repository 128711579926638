import React, { useContext, useEffect, useState } from 'react';
import ComponentCard from '../../components/ComponentCard';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import user1 from '../../assets/images/users/user1.jpg';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast';
import { deleteMenu } from '../../api/restaurant';
import useRestaurantJotai from '../../jotai/restaurant';
import { Context } from '../../jotai';
import { getOrder, getStatus } from '../../api/order';
import PaginationItems from '../../helpers/paginations';
import Loader from '../../layouts/loader/Loader';

const OrderList = () => {

    const navigate = useNavigate()
    const [order, setOrder] = useState([])
    const [orderDetails, setOrderDetails] = useState([])

    const { setSingleMenuJotai } = useRestaurantJotai()
    const [context, setContext] = useContext(Context)

    const [viewModal, setViewModal] = useState(false);
    const [modal, setModal] = useState(false);

    const [delete_id, set_delete_id] = useState();

    const toggle = () => setModal(!modal);
    const viewToggle = () => setViewModal(!viewModal);
    const [pageNumber, setPageNumber] = useState(1);
    const pageLimit = 10;
    const getData = async () => {

        setOrder([])
        const query = {
            page_size: pageLimit,
            page: pageNumber,
            restaurant_id: context?.user?.role !== 'admin' ? context?.user?.id : ''
        }

        const menuData = await getOrder(query)
        setOrder(menuData?.order)
    }

    useEffect(() => {
        getData()
    }, [pageNumber])

    const handleSingleData = (data) => {
        setViewModal(true)
        setOrderDetails(data)
    }

    const handleDelete = async (id) => {
        const res = await deleteMenu(id)
        if (res?.menu?.deletedCount) {
            toast.error('Menu Delete Successfully!')
            getData()
            toggle()
            setSingleMenuJotai()
        }
    }
    const handleSureDelete = (id) => {
        set_delete_id(id)
        toggle()
    }
    const handleOrderStatusChange = async (id, value) => {
        const data = {
            status: value,
            order_id: id
        }
        const response = await getStatus(data)
        if (response?.success) {
            getData()
            toast.success(response?.message)
        } else {
            toast.error(response?.message)
        }
    }
    console.log(orderDetails, "orderorderorder")
    return (
        <>
            <ComponentCard
                title="Recent Order Listing"
                subtitle={
                    <p>
                        Overview of the Order
                    </p>
                }
            >
                <div>

                    <Table className="no-wrap align-middle" responsive borderless>
                        <thead>
                            <tr>
                            <th className='px-4'>S.NO.</th>
                                <th className='px-4'>Order Info</th>
                                <th className='px-4'>Name</th>
                                <th className='px-4'>Status</th>
                                <th className='px-4'>Phone Number</th>
                                {localStorage.getItem('userRole') === 'admin'? <th className='px-4'>Restaurant Name</th> : null}
                                <th className='px-4'>Change Order Status</th>
                                <th className='px-4'>Action</th>
                            </tr>
                        </thead>
                        {

                        }
                        <tbody>
                            {order?.length ? order?.map((tdata,index) => (
                                <tr key={tdata.name} className="border-top">
                                      <td>{pageLimit *(pageNumber-1)+ index+1}.</td>  
                                    <td>
                                        <div className="">
                                            {/* <img
                                                src={tdata.image ? tdata.image : user1}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            /> */}
                                            <div className="ms-3">
                                                <h5 className="mb-0 mb-2 fw-medium">{tdata.order_id}</h5>
                                                <span className="text-muted">{tdata.orderDate}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{tdata.user_id.name}</td>
                                    <td>
                                        {tdata.orderCancel ? (
                                            <span className="p-2 bg-danger rounded-circle d-inline-block ms-3" />
                                        ) :
                                            <span className="p-2 bg-success rounded-circle d-inline-block ms-3" />
                                        }
                                    </td>
                                    <td>{tdata.user_id?.phone_number}</td>
                                    {localStorage.getItem('userRole') === 'admin'? <td><img
                                        src={tdata.restaurant_id?.image ? tdata.restaurant_id?.image : user1}
                                        style={{ borderRadius: "13px" }}
                                        alt="avatar"
                                        width="65"
                                        height="65"
                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tdata.restaurant_id?.name}</td> : null}

                                    <td>
                                        <tr>
                                            <select
                                                className="form-control"
                                                disabled={tdata.orderCancel}
                                                onChange={(e) => handleOrderStatusChange(tdata.id, e.target.value)}
                                                defaultValue={tdata.orderStatus}
                                            >
                                                <option value="order_placed">Order Placed</option>
                                                <option value="order_prepared">Order Prepared</option>
                                                <option value="order_ready_to_pick">Order Ready To Pick</option>
                                                <option value="order_delay">Order Delay</option>
                                                <option value="order_cancel">Order Cancel</option>
                                                <option value="order_delivered">Order Delivered</option>
                                            </select>
                                        </tr>

                                    </td>
                                    <td>
                                        <tr>
                                            <Button color="primary" onClick={() => handleSingleData(tdata)} ><Icon.Eye />&nbsp;&nbsp; Order Details View</Button>

                                            {/* <Button color="primary" onClick={() => handleSingleData(tdata)} ><Icon.Edit /> Edit</Button>
                                            &nbsp;&nbsp;<Button color="danger" onClick={() => handleSureDelete(tdata.id)}><Icon.Trash /> Delete</Button> */}
                                        </tr>

                                    </td>
                                </tr>
                            ))  : <Loader />}
                        </tbody>
                    </Table>
                </div>
                {
                    order?.length > 0 &&
                    <PaginationItems
                        nextBtn={pageLimit > order?.length}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                }
            </ComponentCard>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Order Delete</ModalHeader>
                <ModalBody>
                    Are You Sure!.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        No
                    </Button>
                    <Button color="primary" onClick={() => handleDelete(delete_id)}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
            {/* //view */}
            <Modal size='xl' isOpen={viewModal} toggle={viewToggle}>
                <ModalHeader toggle={viewToggle}>Order Details</ModalHeader>
                <ModalBody>
                    <ComponentCard
                        title={<p>
                            Order ID <b>{orderDetails?.order_id}</b>
                        </p>}
                    // subtitle={
                    //     <p>
                    //         Order ID <b>{orderDetails?.order_id}</b>
                    //     </p>
                    // }
                    >
                        <div>

                            <Table className="no-wrap align-middle" responsive borderless>
                                <thead>
                                    <tr>
                                        <th className='px-4'>Menu Info</th>
                                        <th className='px-4'>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetails?.orderDetails?.map((tdata) => (
                                        <tr key={tdata?.name} className="border-top">
                                            <td>
                                                <div className="d-flex align-items-center p-2">
                                                    <img
                                                        src={tdata.menu?.image ? tdata.menu?.image : user1}
                                                        style={{ borderRadius: "13px" }}
                                                        alt="avatar"
                                                        width="95"
                                                        height="95"
                                                    />
                                                    <div className="ms-3">
                                                        <h5 className="mb-0 mb-2 fw-medium">{tdata.menu?.item_name}</h5>
                                                        <span className="text-muted">{tdata.menu?.price}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{tdata?.quantity}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </ComponentCard>
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        No
                    </Button>
                    <Button color="primary" onClick={() => handleDelete(delete_id)}>
                        Yes
                    </Button>
                </ModalFooter> */}
            </Modal>
        </>
    );
};

export default OrderList;
