import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-validation/build/form';
import ComponentCard from '../../components/ComponentCard';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';
import { getRestaurants, postCreateMenu, postCreateRestaurant, updateMenu, updateRestaurant } from '../../api/restaurant';
import { uploadImage } from '../../api/upload';
import useRestaurantJotai from '../../jotai/restaurant';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { getUsers } from '../../api/users';
import { Context } from '../../jotai';
import { postCreateBanner, updateBanner } from '../../api/banner';
import useBannerJotai from '../../jotai/banner';

const BannerForm = () => {

    const navigate = useNavigate()
    const { singleBannerJotai, setSingleBannerJotai } = useBannerJotai()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    const [restaurant, setRestaurant] = useState()
    const [active, setActive] = useState(singleBannerJotai?.active ? true : false)
    const [context, setContext] = useContext(Context)

    const handleRestaurantData = async () =>{
        const response = await getRestaurants({page_size:50000})
        if(response.success){
            setRestaurant(response.restaurant)
        }
    }
    useEffect(()=>{
      handleRestaurantData()
    },[])


    const onSubmit = async (data) => {
        data.active = active
        data.image = file
        data.restaurant_id = context?.user?.id
        const result = await postCreateBanner(data)
        if (result?.banner) {
            setSingleBannerJotai()
            setActive()
            setFile()
            toast.success('Banner Created Successfully!')
            navigate("/dashboard/banner/list")
        } else {
            toast.error(result.message);
        }
    };

    const onUpdate = async (data) => {
        data.active = active
        data.id = singleBannerJotai.id
        data.image = file
        const result = await updateBanner(data)
        if (result?.banner?.acknowledged) {
            setSingleBannerJotai()
            setActive()
            setFile()
            toast.success('Banner Updated Successfully!')
            navigate("/dashboard/banner/list")
        } else {
            toast.error(result.message);
        }
    };

    const handleFileInput = async (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            try {
                const response = await uploadImage(formData, 'banner');
                if (response.url) {
                    setFile(response.url)
                    toast.success('Image Uploaded Successfully!!')
                } else {
                    toast.error('error in uploading image!')
                }
            } catch (error) {
                console.warn('error: ', error);
            }
        }
    };


    return (
        <>
            <Row>
                <Col sm="12">
                    <ComponentCard title={singleBannerJotai?.id ? "Update Banner" : "Create Banner"}>
                        <Form
                            onSubmit={handleSubmit(singleBannerJotai?.id ? onUpdate : onSubmit)}
                        >
                            <FormGroup>
                                <Label className="control-Label" htmlFor="item_name">
                                Banner Name *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('name', { required: true, pattern: /^(?![\s-])[\w\s-]+$/ })}
                                        className="form-control"
                                        defaultValue={singleBannerJotai?.name || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.name && 'Menu Name is required.'}</span>
                            </FormGroup>
                            <FormGroup className="mb-1">
                                <Label className="control-Label" htmlFor="image">
                                    Image *
                                </Label>
                                <div className="custom-file">
                                    <Input type="file"
                                        {...register('image')}
                                        onChange={handleFileInput}
                                        className="custom-file-input" id="customFile1" />
                                </div>
                                <span className="text-danger">{errors.image && 'Image is required.'}</span>

                            </FormGroup>
                            {
                                file ?
                                    <FormGroup className="mb-1">
                                        <Label className="control-Label" htmlFor="image">
                                            Image View
                                        </Label>
                                        <div className="custom-file">
                                            <img
                                                src={file}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.image && 'Image is required.'}</span>

                                    </FormGroup> : null
                            }
                                                        <FormGroup>
                                <Label className="control-Label" htmlFor="role">
                                    Restaurant *
                                </Label>
                                <div className="mb-2">
                                    <select
                                        className="form-control"
                                        {...register('restaurant_id', { required: true })}
                                        // defaultValue={singleUserJotai?.restaurant_id || ''}
                                    >
                                        <option value="">Select Option</option>
                                        {
                                            restaurant?.map((restaurant,index)=>{
                                                return(
                                                    <>
                                                    <option value={restaurant.id}>{restaurant.name}</option>
                                                    </>
                                                )
                                            })
                                        }
                                        {/* <option value="admin">Admin</option> */}
                                    </select>
                                </div>
                                <span className="text-danger">{errors.restaurant_id && 'Please select value.'}</span>
                            </FormGroup>
                            <FormGroup>
                                <Switch onText="Active" onChange={() => setActive(active ? false : true)} offText="Deactive" defaultValue={active} />
                            </FormGroup>
                            <FormGroup>
                                {singleBannerJotai?.id ?
                                    <Button className="button btn-info" type="submit">
                                        Update
                                    </Button>
                                    :
                                    <Button className="button btn-info" type="submit">
                                        Submit
                                    </Button>
                                }
                            </FormGroup>
                        </Form>
                    </ComponentCard>
                </Col>
            </Row>
        </>
    );
};

export default BannerForm;
