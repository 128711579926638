import { APIClient } from "./apiCore";
import * as url from "./urls";
const api = new APIClient();

//Order
export const getOrder = (data) => {
    return api.get(url.ORDER, { params: { restaurant_id:data?.restaurant_id, page:data?.page,pageSize:data?.page_size  } });
}

export const getStatus = (data) => {
    return api.create(url.ORDER_STATUS, data);
}

