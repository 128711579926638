import { useAtom } from "jotai";
import { Restaurant,Menu } from ".";


const useRestaurantJotai = () =>{

    const [singleRestaurantJotai, setSingleRestaurantJotai] = useAtom(Restaurant)
    const [singleMenuJotai, setSingleMenuJotai] = useAtom(Menu)

    return {
        singleRestaurantJotai, setSingleRestaurantJotai,
        singleMenuJotai, setSingleMenuJotai
    }
}
export default useRestaurantJotai