import React, { useEffect, useState } from 'react';
import { Button, Label, FormGroup, Container, Row, Col, Card, CardBody, Input } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import AuthLogo from "../../layouts/logo/AuthLogo";
import { ReactComponent as LeftBg } from '../../assets/images/bg/login-bgleft.svg';
import { ReactComponent as RightBg } from '../../assets/images/bg/login-bg-right.svg';
import { postAdminLogin, postRestaurantLogin } from '../../api/auth';
import { toast } from "wc-toast";


const LoginFormik = () => {
  const navigate = useNavigate();
  const access_token = localStorage.getItem('accessToken');
  const [userType, setUserType] = useState('admin')

  const [loading,setLoading] = useState(false)
  const initialValues = {
    email: '',
    password: '',
  };

  useEffect(()=>{
    if(access_token){
      navigate('/dashboard')
    }
  },[])
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .min(2, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const handleLogin =  async (values) =>{
    setLoading(true)
    values.type = userType
    const result = userType === 'admin' ? await postAdminLogin(values) : await postRestaurantLogin(values)
    console.log(result,"resultresult")
    if (result?.user?.active) {
      setLoading(false)
      // setLoginUserJotai(result?.user)
      toast.loading("Authenticating...",{duration:500})
      // window.location.href = "/dashboard"
      setTimeout(function() {
        toast.success('Login Successfully!')
        window.location.href = "/dashboard"
      }, 1000);
      localStorage.setItem('accessToken',result?.accessToken)
      localStorage.setItem('userRole',userType)
      localStorage.setItem('refreshToken',result?.refreshToken)
      
    } else {
      setLoading(false)
      toast.error(result.message);
    }
  }


  return (
    <div className="loginBox">
      <LeftBg className="position-absolute left bottom-0" />
      <RightBg className="position-absolute end-0 top" />
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="12" className="loginContainer">
            <AuthLogo />
            <Card>
              <CardBody className="p-4 m-1">
                {/* <h5 className="mb-0">Login</h5> */}
                {/* <small className="pb-4 d-block">
                  Do not have an account? <Link to="/auth/registerformik">Sign Up</Link>
                </small> */}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(fields) => handleLogin(fields)}
                  render={({ errors, touched }) => (
                    <Form>
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Field
                          name="email"
                          type="text"
                          className={`form-control${
                            errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Field
                          name="password"
                          type="password"
                          className={`form-control${
                            errors.password && touched.password ? ' is-invalid' : ''
                          }`}
                          
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                      <FormGroup>
                      <FormGroup check inline>
                        <Input defaultChecked={userType==='admin'} type="radio" name="customcheck1" onClick={()=>setUserType('admin')} />
                        <Label check>Admin</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input defaultChecked={userType==='restaurant'} type="radio" name="customcheck1" onClick={()=>setUserType('restaurant')} />
                        <Label check>Restaurant</Label>
                      </FormGroup>
                      {/* <FormGroup check inline>
                        <Input defaultChecked={userType==='staff'} type="radio" name="customcheck1" onClick={()=>setUserType('staff')}/>
                        <Label check>Staff</Label>
                      </FormGroup> */}
                    </FormGroup>
                      <FormGroup>
                        <Button  disabled={loading} type="submit" color="primary" className="me-2">
                          Login
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginFormik;
