import React, { useEffect, useState } from 'react';
import ComponentCard from '../../components/ComponentCard';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import user1 from '../../assets/images/users/user1.jpg';
import { getUsers } from '../../api/users';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import useUserJotai from '../../jotai/user';
import { deleteUser } from '../../api/auth';
import { toast } from 'wc-toast';
import Loader from '../../layouts/loader/Loader';
import { Pagination } from 'flowbite-react';
import noDataFound from '../../assets/images/no.gif';

const UserList = () => {


    const navigate = useNavigate()
    const [users, setUsers] = useState([])
    const { setSingleJotai } = useUserJotai()
    const [modal, setModal] = useState(false);
    const [delete_id, set_delete_id] = useState();
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const toggle = () => setModal(!modal);




    const handleSingleData = (data) => {
        setSingleJotai(data)
        navigate(`/dashboard/user/create`)
    }

    const handleDelete = async (id) => {
        const res = await deleteUser(id)
        if (res?.users?.deletedCount) {
            toast.error('User Delete Successfully!')
            getData()
            toggle()
            setSingleJotai()
        }
    }
    const handleSureDelete = (id) => {
        set_delete_id(id)
        toggle()
    }



    const pageLimit = 5;
    const [pageNumber, setPageNumber] = useState(1);
    const onPageChange = (page) => setPageNumber(page);
    const [q, setQ] = useState("");


    const getData = async () => {
        setUsers([])
        const query = {
            page_size: pageLimit,
            page: pageNumber,
            q: q
        }
        const userData = await getUsers(query)
        setUsers(userData)
    }


    useEffect(() => {
        getData()
    }, [pageNumber,q])

    return (
        <>
            <ComponentCard
                title="User Listing"
                subtitle={
                    <>
                        <span>Search User</span>
                        <input
                            type="text"
                            onChange={(e) => setQ(e.target.value)}
                            className="form-control"
                            style={{ width: "250px", paddingTop: "5px" }}
                        />
                    </>
                }
            >
                <div>

                    <Table className="no-wrap" responsive >
                        <thead>
                            <tr>
                                <th>S.NO.</th>
                                <th>User Info</th>
                                <th>Phone Number</th>
                                <th>Status</th>
                                <th>User Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.users?.length ? users?.users?.map((tdata, index) => (

                                        <tr key={tdata.name} className="border-top">
                                            <td>{pageLimit * (pageNumber - 1) + index + 1}.</td>
                                            <td> <div className="d-flex align-items-center p-2">
                                                    <img
                                                        src={tdata.avatar ? tdata.avatar : user1}
                                                        className="rounded-circle"
                                                        alt="avatar"
                                                        width="45"
                                                        height="45"
                                                    />
                                                    <div className="ms-3">
                                                        <h5 className="mb-0 fw-medium">{tdata.name}</h5>
                                                        <span className="text-muted">{tdata.email}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{tdata.phone_number}</td>
                                            <td>
                                                {tdata.status == true ? (
                                                    <span className="p-2 bg-danger rounded-circle d-inline-block ms-3" />
                                                ) : tdata.status === 'holt' ? (
                                                    <span className="p-2 bg-warning rounded-circle d-inline-block ms-3" />
                                                ) : (
                                                    <span className="p-2 bg-success rounded-circle d-inline-block ms-3" />
                                                )}
                                            </td>
                                            <td>{tdata.role}</td>
                                            <td>
                                                <tr>
                                                    <Button color="primary" onClick={() => handleSingleData(tdata)} ><Icon.Edit /> Edit</Button>
                                                    &nbsp;&nbsp;<Button color="danger" onClick={() => handleSureDelete(tdata.id)}><Icon.Trash /> Delete</Button></tr>

                                            </td>
                                        </tr>
                                    )) : users?.users?.length === 0 ?
                                        <div className="nodataFound">
                                            <div className="noDataloading">
                                                <img src={noDataFound}  />
                                            </div>
                                        </div>
                                        : <Loader />}
                        </tbody>
                    </Table>
                </div>
                {
                    users?.users?.length ?
                        <div className=" page-pagination">
                            <Pagination currentPage={pageNumber} totalPages={users?.pages} onPageChange={onPageChange} />
                        </div> : null
                }


            </ComponentCard>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>User Delete</ModalHeader>
                <ModalBody>
                    Are You Sure!.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        No
                    </Button>
                    <Button color="success" onClick={() => handleDelete(delete_id)}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default UserList;
