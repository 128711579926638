import React, { useContext, useEffect, useState } from 'react';
import ComponentCard from '../../components/ComponentCard';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import user1 from '../../assets/images/users/user1.jpg';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast';
import { deleteMenu, getMenus } from '../../api/restaurant';
import useRestaurantJotai from '../../jotai/restaurant';
import { Context } from '../../jotai';
import PaginationItems from '../../helpers/paginations';
import Loader from '../../layouts/loader/Loader';

const MenuList = () => {

    const navigate = useNavigate()
    const [menus, setMenu] = useState([])
    const { setSingleMenuJotai } = useRestaurantJotai()
    const [context, setContext] = useContext(Context)

    const [modal, setModal] = useState(false);
    const [delete_id, set_delete_id] = useState();

    const toggle = () => setModal(!modal);
    const [pageNumber, setPageNumber] = useState(1);
    const pageLimit = 4;
    const getData = async () => {
        const restaurant_id = context?.user?.role !== 'admin' ? context?.user?.id : ''
        setMenu([])
        const query = {
            page_size: pageLimit,
            page: pageNumber,
            restaurant_id: restaurant_id
        }
        const menuData = await getMenus(query)
        setMenu(menuData?.menu)
    }
    useEffect(() => {
        getData()
    }, [pageNumber])

    const handleSingleData = (data) => {
        setSingleMenuJotai(data)
        navigate(`/dashboard/menu/create`)
    }

    const handleDelete = async (id) => {
        const res = await deleteMenu(id)
        if (res?.menu?.deletedCount) {
            toast.error('Menu Delete Successfully!')
            getData()
            toggle()
            setSingleMenuJotai()
        }
    }
    const handleSureDelete = (id) => {
        set_delete_id(id)
        toggle()
    }
    return (
        <>
            <ComponentCard
                title={`Menu Listing`}
                subtitle={
                    <p>
                        Overview of the Menu
                    </p>
                }
            >
                <div>

                    <Table className="no-wrap align-middle" responsive >
                        <thead>
                            <tr>
                                <th className='px-4'>S.NO.</th>
                                <th className='px-4'>Menu Info</th>
                                <th className='px-4'>Item Name</th>
                                <th className='px-4'>Status</th>
                                <th className='px-4'>Price</th>
                                {localStorage.getItem('userRole') === 'admin'? <th className='px-4'>Restaurant Name</th> : null}
                                <th className='px-4'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {menus?.length ? menus?.map((tdata,index) => (
                                <tr key={tdata.name} className="border-top">
                                    <td>{pageLimit *(pageNumber-1)+ index+1}.</td>
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                            <img
                                                src={tdata.image ? tdata.image : user1}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            />
                                            <div className="ms-3">
                                                <h5 className="mb-0 fw-medium">{tdata.item_name}</h5>
                                                {/* <span className="text-muted">{tdata.email}</span> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{tdata.item_name}</td>
                                    <td>
                                        {tdata.active ? (
                                            <span className="p-2 bg-success rounded-circle d-inline-block ms-3" />
                                        ) :
                                            <span className="p-2 bg-danger rounded-circle d-inline-block ms-3" />
                                        }
                                    </td>
                                    <td>{tdata.price}</td>
                                    {localStorage.getItem('userRole') === 'admin'? <td><img
                                        src={tdata.restaurant_id?.image ? tdata.restaurant_id?.image : user1}
                                        style={{ borderRadius: "13px" }}
                                        alt="avatar"
                                        width="65"
                                        height="65"
                                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tdata.restaurant_id?.name}</td> : null}

                                    <td>
                                        <tr>
                                            <Button color="primary" onClick={() => handleSingleData(tdata)} ><Icon.Edit /> Edit</Button>
                                            &nbsp;&nbsp;<Button color="danger" onClick={() => handleSureDelete(tdata.id)}><Icon.Trash /> Delete</Button></tr>

                                    </td>
                                </tr>
                            )) :  <Loader />}
                        </tbody>
                    </Table>
                </div>
                {
                    menus?.length > 0 &&
                    <PaginationItems
                        nextBtn={pageLimit > menus?.length}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                }
            </ComponentCard>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Restaurant Delete</ModalHeader>
                <ModalBody>
                    Are You Sure!.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        No
                    </Button>
                    <Button color="primary" onClick={() => handleDelete(delete_id)}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default MenuList;
