import authHeader from "../helpers/jwt-token-access/auth-token-header";
import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

export const postAdminLogin = (data) => api.create(url.POST_STAFF_LOGIN, data);
export const postRestaurantLogin = (data) => api.create(url.RESTAURANT_LOGIN, data);

export const postCreateUser = (data) => api.create(url.POST_CREATE_USER, data);
export const updateUser = (data) => api.update(url.POST_CREATE_USER, data);
export const deleteUser = (id) => {
    return api.delete(url.POST_CREATE_USER, { params: { id } });
}
export const verifyToken = () => {
    try{
        const auth = authHeader();
        return api.create(url.VERIFY_TOKEN, { token:auth.withOutBearertoken,role:auth.userRole });

    }catch(err){
        console.log(err,"444444444444444444444444444")
    }
}

