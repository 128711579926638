import { useAtom } from "jotai";
import { SingleUser } from ".";


const useUserJotai = () =>{

    const [singleUserJotai, setSingleJotai] = useAtom(SingleUser)

    return {
        singleUserJotai, setSingleJotai,
    }
}
export default useUserJotai