export const DASHBOARD = "/home/dashboard"

//auth
export const POST_STAFF_LOGIN = "/auth/staff_login";
export const POST_CREATE_USER = "/users";
export const VERIFY_TOKEN = "/auth/verifytoken";

//restaurant
export const RESTAURANT = "/restaurant"
export const RESTAURANT_MENU ="/restaurant/menu"
export const RESTAURANT_LOGIN ="/restaurant/login"

//Image
export const IMAGE = "/upload_image"

//Category
export const CATEGORY = "/category"


export const ORDER = "/order"
export const ORDER_STATUS = "/order/status"

export const BANNER = "/home/banner"

export const NOTIFICATION = "/home/notification"