import { useAtom } from "jotai";
import { Banner } from ".";


const useBannerJotai = () =>{

    const [singleBannerJotai, setSingleBannerJotai] = useAtom(Banner)

    return {
        singleBannerJotai, setSingleBannerJotai
       
    }
}
export default useBannerJotai