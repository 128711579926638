import { useAtom } from "jotai";
import { Category } from ".";


const useCategoryJotai = () =>{

    const [singleCategoryJotai, setSingleCategoryJotai] = useAtom(Category)

    return {
        singleCategoryJotai, setSingleCategoryJotai
       
    }
}
export default useCategoryJotai