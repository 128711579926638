import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-validation/build/form';
import ComponentCard from '../../components/ComponentCard';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';
import { postCreateMenu, postCreateRestaurant, updateMenu, updateRestaurant } from '../../api/restaurant';
import { uploadImage } from '../../api/upload';
import useRestaurantJotai from '../../jotai/restaurant';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { getUsers } from '../../api/users';
import { Context } from '../../jotai';
import { getCategory } from '../../api/category';
import { postCreateNotification } from '../../api/notifications';

const NotificationsForm = () => {

    const navigate = useNavigate()
    const { singleMenuJotai, setSingleMenuJotai } = useRestaurantJotai()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    const [type, setType] = useState("")
    const [active, setActive] = useState()
    const [category, setCategory] = useState([])
    const [context, setContext] = useContext(Context)
    const [msg, setMsg] = useState()
    const [pageNumber, setPageNumber] = useState(1);
    const pageLimit = 100;
    const getData = async () => {
        const restaurant_id = context?.user?.role !== 'admin' ? context?.user?.id : ''
        const query = {
            page_size: pageLimit,
            page: pageNumber,
            restaurant_id: context?.user?.role !== 'admin' ? context?.user?.id : ''
        }
        const userData = await getCategory(query)
        setCategory(userData?.category)
    }

    useEffect(() => {
        getData()
    }, [])

    const [users, setUsers] = useState([])

    const getUserData = async () => {
        const userData = await getUsers({ pageSize: 5000 })
        setUsers(userData?.users)
    }

    useEffect(() => {
        getUserData()
    }, [])

    const onSubmit = async (data) => {
        data.active = active
        data.image = file
        data.msg = msg
        const result = await postCreateNotification(data)
        if (result?.success) {
            setSingleMenuJotai()
            setActive()
            setFile()
            toast.success('Notification Created Successfully!')
            navigate("/dashboard/notification/list")
        } else {
            toast.error(result.message);
        }
    };

    const onUpdate = async (data) => {
        data.active = active
        data.id = singleMenuJotai.id
        data.image = file
        const result = await updateMenu(data)
        if (result?.menu?.acknowledged) {
            setSingleMenuJotai()
            setActive()
            setFile()
            toast.success('Notification Updated Successfully!')
            navigate("/dashboard/notification/list")
        } else {
            toast.error(result.message);
        }
    };

    const handleFileInput = async (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            try {
                const response = await uploadImage(formData, 'menu');
                if (response.url) {
                    setFile(response.url)
                    toast.success('Image Uploaded Successfully!!')
                } else {
                    toast.error('error in uploading image!')
                }
            } catch (error) {
                console.warn('error: ', error);
            }
        }
    };


    return (
        <>
            <Row>
                <Col sm="12">
                    <ComponentCard title={singleMenuJotai?.id ? "Update Notification" : "Create Notification"}>
                        <Form
                            onSubmit={handleSubmit(singleMenuJotai?.id ? onUpdate : onSubmit)}
                        >
                            <FormGroup>
                                <Label className="control-Label" htmlFor="name">
                                    Notification Name *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('name', { required: true, pattern: /^(?![\s-])[\w\s-]+$/ })}
                                        className="form-control"
                                        defaultValue={singleMenuJotai?.name || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.name && 'Notification Name is required.'}</span>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-Label" htmlFor="role">
                                    Notification type
                                </Label>
                                <div className="mb-2">
                                    <select
                                        className="form-control"
                                        {...register('type', { required: true })}
                                        defaultValue={singleMenuJotai?.type || ''}
                                        onChange={(e)=>setType(e.target.value)}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="user">Single User</option>
                                        <option value="all">All User</option>
                                    </select>
                                </div>
                                <span className="text-danger">{errors.type && 'Please select value.'}</span>
                            </FormGroup>
                            {
                                type === 'user' &&
                            <FormGroup>
                                <Label className="control-Label" htmlFor="role">
                                    Send To *
                                </Label>
                                <div className="mb-2">
                                    <select
                                        className="form-control"
                                        {...register('user', { required: true })}
                                    // defaultValue={singleRestaurantJotai?.user || ''}
                                    >
                                        <option value="">Select Option</option>
                                        {
                                            users?.map((user, index) => {
                                                return (
                                                    <>
                                                        <option value={user.id}><b>{`${user.name}(${user.email}) `}</b></option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <span className="text-danger">{errors.user && 'Please select value.'}</span>
                            </FormGroup>
                            }
                            <FormGroup className="mb-1">
                                <Label className="control-Label" htmlFor="image">
                                    Image *
                                </Label>
                                <div className="custom-file">
                                    <Input type="file"
                                        {...register('image')}
                                        onChange={handleFileInput}
                                        className="custom-file-input" id="customFile1" />
                                </div>
                                <span className="text-danger">{errors.image && 'Image is required.'}</span>

                            </FormGroup>
                            {
                                file ?
                                    <FormGroup className="mb-1">
                                        <Label className="control-Label" htmlFor="image">
                                            Image View
                                        </Label>
                                        <div className="custom-file">
                                            <img
                                                src={file}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.image && 'Image is required.'}</span>

                                    </FormGroup> : null
                            }


                            <FormGroup>
                                <Label className="control-Label" htmlFor="description">
                                    Description
                                </Label>

                                <Input type="textarea"
                                    {...register('msg')}
                                    onChange={(event) => setMsg(event.target.value)}
                                    defaultValue={singleMenuJotai?.msg}
                                    placeholder="Textarea Text" rows="3" />
                                {/* <span className="text-danger">{errors.description && 'description is required.'}</span> */}
                            </FormGroup>
                            <FormGroup>
                                <Switch onText="Active" onChange={() => setActive(singleMenuJotai?.active ? false : true)} offText="Deactive" defaultValue={singleMenuJotai?.active} />
                            </FormGroup>
                            <FormGroup>
                                {singleMenuJotai?.id ?
                                    <Button className="button btn-info" type="submit">
                                        Update
                                    </Button>
                                    :
                                    <Button className="button btn-info" type="submit">
                                        Submit
                                    </Button>
                                }
                            </FormGroup>
                        </Form>
                    </ComponentCard>
                </Col>
            </Row>
        </>
    );
};

export default NotificationsForm;


