import React, { useEffect, useState } from 'react';
import ComponentCard from '../../components/ComponentCard';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import user1 from '../../assets/images/users/user1.jpg';
import { getUsers } from '../../api/users';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import useUserJotai from '../../jotai/user';
import { deleteUser } from '../../api/auth';
import { toast } from 'wc-toast';
import { deleteRestaurant, getRestaurants } from '../../api/restaurant';
import useRestaurantJotai from '../../jotai/restaurant';
import PaginationItems from '../../helpers/paginations';
import Loader from '../../layouts/loader/Loader';
import { Pagination } from 'flowbite-react';

const RestaurantList = () => {

    const navigate = useNavigate()
    const [restaurants, setRestaurant] = useState([])
    const { setSingleRestaurantJotai } = useRestaurantJotai()

    const [modal, setModal] = useState(false);
    const [delete_id, set_delete_id] = useState();

    const toggle = () => setModal(!modal);

    const getData = async () => {
        setRestaurant([])
        const query = {
            page_size: pageLimit,
            page: pageNumber,
            q:q
        }
        const restaurantData = await getRestaurants(query)
        setRestaurant(restaurantData?.restaurant)
    }

    const [pageNumber, setPageNumber] = useState(1);
    const onPageChange = (page) => setPageNumber(page);
    const [q, setQ] = useState("");
    const pageLimit = 5;

    useEffect(() => {
        getData()
    }, [pageNumber,q])


    const handleSingleData = (data) => {
        setSingleRestaurantJotai(data)
        navigate(`/dashboard/restaurant/create`)
    }

    const handleDelete = async (id) => {
        const res = await deleteRestaurant(id)
        if (res?.restaurant?.deletedCount) {
            toast.error('Restaurant Delete Successfully!')
            getData()
            toggle()
            setSingleRestaurantJotai()
        }
    }
    const handleSureDelete = (id) => {
        set_delete_id(id)
        toggle()
    }

    return (
        <>
            <ComponentCard
                title="Restaurant Listing"
                subtitle={
                    <>
                        <span>Search Restaurant</span>
                        <input
                            type="text"
                            onChange={(e) => setQ(e.target.value)}
                            className="form-control"
                            style={{ width: "250px", paddingTop: "5px" }}
                        />
                    </>
                }
            >
                <div>

                    <Table className="no-wrap align-middle" responsive borderless>
                        <thead>
                            <tr>
                            <th>S.NO.</th>
                                <th>Restaurant Info</th>
                                <th>Status</th>
                                <th>Address</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            restaurants?.length ?
                            restaurants?.map((tdata,index) => (
                                <tr key={tdata.name} className="border-top">
                                    <td>{pageLimit *(pageNumber-1)+ index+1}.</td>
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                            <img
                                                src={tdata.image ? tdata.image : user1}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            />
                                            <div className="ms-3">
                                                <h5 className="mb-0 fw-medium">{tdata.name}</h5>
                                                <span className="text-muted">{tdata.email}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {tdata.active ? (
                                            <span className="p-2 bg-success rounded-circle d-inline-block ms-3" />
                                        ) :
                                            <span className="p-2 bg-danger rounded-circle d-inline-block ms-3" />
                                        }
                                    </td>
                                    <td>{tdata.address}</td>
                                    <td>
                                        <tr>
                                            <Button color="primary" onClick={() => handleSingleData(tdata)} ><Icon.Edit /> Edit</Button>
                                            &nbsp;&nbsp;<Button color="danger" onClick={() => handleSureDelete(tdata.id)}><Icon.Trash /> Delete</Button></tr>

                                    </td>
                                </tr>
                            )):<Loader />}
                        </tbody>
                    </Table>
                </div>
                {/* {
                    restaurants?.length ?
                        <div className=" page-pagination">
                            <Pagination currentPage={pageNumber} totalPages={restaurants?.pages} onPageChange={onPageChange} />
                        </div> : null
                } */}
                {/* <PaginationItems
                    nextBtn={pageLimit > restaurants?.length}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                /> */}

            </ComponentCard>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Restaurant Delete</ModalHeader>
                <ModalBody>
                    Are You Sure!.
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        No
                    </Button>
                    <Button color="primary" onClick={() => handleDelete(delete_id)}>
                        Yes
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default RestaurantList;
