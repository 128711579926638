import React, { useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-validation/build/form';
import ComponentCard from '../../components/ComponentCard';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';
import { postCreateRestaurant, updateRestaurant } from '../../api/restaurant';
import { uploadImage } from '../../api/upload';
import useRestaurantJotai from '../../jotai/restaurant';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { getUsers } from '../../api/users';

const RestaurantForm = () => {

    const navigate = useNavigate()
    const { singleRestaurantJotai, setSingleRestaurantJotai } = useRestaurantJotai()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    const [address, setAddress] = useState()
    const [active, setActive] = useState(false)
    const [users, setUsers] = useState([])

    const getData = async () => {
        const userData = await getUsers()
        setUsers(userData?.users)
    }

    useEffect(() => {
        getData()
    }, [])


    const onSubmit = async (data) => {
        data.active = active
        data.image = file
        data.address = address
        const result = await postCreateRestaurant(data)
        if (result?.restaurant) {
            setSingleRestaurantJotai()
            setActive()
            setAddress()
            setFile()
            toast.success('Restaurant Created Successfully!')
            navigate("/dashboard/restaurant/list")
        } else {
            toast.error(result.message);
        }
    };

    const onUpdate = async (data) => {
        data.active = active
        data.id = singleRestaurantJotai.id
        data.image = file
        data.address = address
        const result = await updateRestaurant(data)
        if (result?.restaurant?.acknowledged) {
            setSingleRestaurantJotai()
            setActive()
            setAddress()
            setFile()
            toast.success('Restaurant Updated Successfully!')
            navigate("/dashboard/restaurant/list")
        } else {
            toast.error(result.message);
        }
    };

    const handleFileInput = async (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            try {
                const response = await uploadImage(formData, 'restaurant');
                if (response.url) {
                    setFile(response.url)
                    toast.success('Image Uploaded Successfully!!')
                } else {
                    toast.error('error in uploading image!')
                }
            } catch (error) {
                console.warn('error: ', error);
            }
        }
    };


    return (
        <>
            <Row>
                <Col sm="12">
                    <ComponentCard title={singleRestaurantJotai?.id ? "Update Restaurant" : "Create Restaurant"}>
                        <Form
                            onSubmit={handleSubmit(singleRestaurantJotai?.id ? onUpdate : onSubmit)}
                        >
                            <FormGroup>
                                <Label className="control-Label" htmlFor="firstname">
                                    Restaurant Name *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('name', { required: true, pattern: /^(?![\s-])[\w\s-]+$/ })}
                                        className="form-control"
                                        defaultValue={singleRestaurantJotai?.name || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.name && 'Name is required.'}</span>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-Label" htmlFor="phone_number">
                                    Phone Number *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('phone_number', { required: true, maxLength: 11, minLength: 8 })}
                                        className="form-control"
                                        defaultValue={singleRestaurantJotai?.phone_number || ''}
                                    />
                                </div>
                                <span className="text-danger">
                                    {errors.phone_number && 'Enter a Valid phone number.'}
                                </span>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-Label" htmlFor="email">
                                    Email *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                                        className="form-control"
                                        defaultValue={singleRestaurantJotai?.email || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.email && 'Email is required.'}</span>
                            </FormGroup>
                            {
                                !singleRestaurantJotai?.id &&
                            <FormGroup>
                                <Label className="control-Label" htmlFor="password">
                                    Password *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('password', { required: true })}
                                        className="form-control"
                                        defaultValue={singleRestaurantJotai?.password || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.password && 'Password is required.'}</span>
                            </FormGroup>
                            }
                            <FormGroup className="mb-1">
                                <Label className="control-Label" htmlFor="image">
                                    Image *
                                </Label>
                                <div className="custom-file">
                                    <Input type="file"
                                        {...register('image')}
                                        onChange={handleFileInput}
                                        className="custom-file-input" id="customFile1" />
                                </div>
                                <span className="text-danger">{errors.image && 'Image is required.'}</span>

                            </FormGroup>
                            {
                                file ?
                                    <FormGroup className="mb-1">
                                        <Label className="control-Label" htmlFor="image">
                                            Image View
                                        </Label>
                                        <div className="custom-file">
                                            <img
                                                src={file}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.image && 'Image is required.'}</span>

                                    </FormGroup> : null
                            }
                        
                            <FormGroup>
                                <Label className="control-Label" htmlFor="address">
                                    Address
                                </Label>

                                <Input type="textarea"
                                    {...register('address')}
                                    onChange={(event) => setAddress(event.target.value)}
                                    defaultValue={singleRestaurantJotai?.address}
                                    placeholder="Textarea Text" rows="3" />
                                {/* <span className="text-danger">{errors.address && 'Address is required.'}</span> */}
                            </FormGroup>
                            {
                                singleRestaurantJotai?.user ?
                                    <FormGroup>
                                        <Label className="control-Label" htmlFor="role">
                                            Restaurant Owner *
                                        </Label>
                                        <div className="mb-2 text-success">
                                            {singleRestaurantJotai?.user.name+"("+singleRestaurantJotai?.user.email+", "+singleRestaurantJotai?.user.phone_number+")"}
                                        </div>
                                        <span className="text-danger">{errors.role && 'Please select value.'}</span>
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label className="control-Label" htmlFor="role">
                                            Restaurant Owner *
                                        </Label>
                                        <div className="mb-2">
                                            <select
                                                className="form-control"
                                                {...register('user', { required: true })}
                                                defaultValue={singleRestaurantJotai?.user || ''}
                                            >
                                                <option value="">Select Option</option>
                                                {
                                                    users?.map((user, index) => {
                                                        return (
                                                            <>
                                                                <option value={user.id}><b>{`${user.name}(${user.email}) `}</b></option>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <span className="text-danger">{errors.user && 'Please select value.'}</span>
                                    </FormGroup>
                            }
                            <FormGroup>
                                <Switch onText="Active" onChange={() => setActive(singleRestaurantJotai?.active ? false : true)} offText="Deactive" defaultValue={singleRestaurantJotai?.active} />
                            </FormGroup>
                            <FormGroup>
                                {singleRestaurantJotai?.id ?
                                    <Button className="button btn-info" type="submit">
                                        Update
                                    </Button>
                                    :
                                    <Button className="button btn-info" type="submit">
                                        Submit
                                    </Button>
                                }
                            </FormGroup>
                        </Form>
                    </ComponentCard>
                </Col>
            </Row>
        </>
    );
};

export default RestaurantForm;
