import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Label, ListGroup, ListGroupItem, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-validation/build/form';
import ComponentCard from '../../components/ComponentCard';
import { toast } from 'wc-toast';
import { useNavigate } from 'react-router-dom';
import { postCreateMenu, postCreateRestaurant, updateMenu, updateRestaurant } from '../../api/restaurant';
import { uploadImage } from '../../api/upload';
import useRestaurantJotai from '../../jotai/restaurant';
import Switch from 'react-bootstrap-switch';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { getUsers } from '../../api/users';
import { Context } from '../../jotai';
import { getCategory } from '../../api/category';

const MenuForm = () => {

    const navigate = useNavigate()
    const { singleMenuJotai, setSingleMenuJotai } = useRestaurantJotai()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [file, setFile] = useState()
    const [active, setActive] = useState()
    const [category, setCategory] = useState([])
    const [context, setContext] = useContext(Context)
    const [description, setDescription] = useState()
    const [pageNumber, setPageNumber] = useState(1);
    const pageLimit = 100;
    const getData = async () => {
        const restaurant_id = context?.user?.role !== 'admin' ? context?.user?.id : ''
        const query = {
            page_size: pageLimit,
            page: pageNumber,
            restaurant_id: context?.user?.role !== 'admin' ? context?.user?.id : ''
        }
        const userData = await getCategory(query)
        setCategory(userData?.category)
    }

    useEffect(() => {
        getData()
    }, [])

    const onSubmit = async (data) => {
        data.active = active
        data.image = file
        data.restaurant_id = context?.user?.id
        data.currency = '$'
        data.description = description
        const result = await postCreateMenu(data)
        if (result?.menu) {
            setSingleMenuJotai()
            setActive()
            setFile()
            toast.success('Menu Created Successfully!')
            navigate("/dashboard/menu/list")
        } else {
            toast.error(result.message);
        }
    };

    const onUpdate = async (data) => {
        data.active = active
        data.id = singleMenuJotai.id
        data.image = file
        data.description = description
        const result = await updateMenu(data)
        if (result?.menu?.acknowledged) {
            setSingleMenuJotai()
            setActive()
            setFile()
            toast.success('Menu Updated Successfully!')
            navigate("/dashboard/menu/list")
        } else {
            toast.error(result.message);
        }
    };

    const handleFileInput = async (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            try {
                const response = await uploadImage(formData, 'menu');
                if (response.url) {
                    setFile(response.url)
                    toast.success('Image Uploaded Successfully!!')
                } else {
                    toast.error('error in uploading image!')
                }
            } catch (error) {
                console.warn('error: ', error);
            }
        }
    };


    return (
        <>
            <Row>
                <Col sm="12">
                    <ComponentCard title={singleMenuJotai?.id ? "Update Menu" : "Create Menu"}>
                        <Form
                            onSubmit={handleSubmit(singleMenuJotai?.id ? onUpdate : onSubmit)}
                        >
                            <FormGroup>
                                <Label className="control-Label" htmlFor="item_name">
                                    Menu Name *
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('item_name', { required: true, pattern: /^(?![\s-])[\w\s-]+$/ })}
                                        className="form-control"
                                        defaultValue={singleMenuJotai?.item_name || ''}
                                    />
                                </div>
                                <span className="text-danger">{errors.item_name && 'Menu Name is required.'}</span>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-Label" htmlFor="price">
                                    Price ({'$'})*
                                </Label>
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        {...register('price', { required: true, maxLength: 8, minLength: 1 })}
                                        className="form-control"
                                        defaultValue={singleMenuJotai?.price || ''}
                                    />
                                </div>
                                <span className="text-danger">
                                    {errors.price && 'Enter a Valid price.'}
                                </span>
                            </FormGroup>
                            <FormGroup className="mb-1">
                                <Label className="control-Label" htmlFor="image">
                                    Image *
                                </Label>
                                <div className="custom-file">
                                    <Input type="file"
                                        {...register('image')}
                                        onChange={handleFileInput}
                                        className="custom-file-input" id="customFile1" />
                                </div>
                                <span className="text-danger">{errors.image && 'Image is required.'}</span>

                            </FormGroup>
                            {
                                file ?
                                    <FormGroup className="mb-1">
                                        <Label className="control-Label" htmlFor="image">
                                            Image View
                                        </Label>
                                        <div className="custom-file">
                                            <img
                                                src={file}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="95"
                                                height="95"
                                            />
                                        </div>
                                        <span className="text-danger">{errors.image && 'Image is required.'}</span>

                                    </FormGroup> : null
                            }
                            {
                                singleMenuJotai?.category_id ?
                                    <FormGroup>
                                        <Label className="control-Label" htmlFor="role">
                                            Menu Category *
                                        </Label>
                                        <div className="mb-2 text-success">
                                            <img
                                                src={singleMenuJotai?.category_id.image}
                                                style={{ borderRadius: "13px" }}
                                                alt="avatar"
                                                width="55"
                                                height="55"
                                            /><br /> <div className='p-1'><h4>{singleMenuJotai?.category_id.name}</h4></div>
                                        </div>
                                    </FormGroup>
                                    :
                                    <FormGroup>
                                        <Label className="control-Label" htmlFor="role">
                                            Menu Category *
                                        </Label>
                                        <div className="mb-2">
                                            <select
                                                className="form-control"
                                                {...register('category_id', { required: true })}
                                                defaultValue={singleMenuJotai?.category_id || ''}
                                            >
                                                <option value="">Select Option</option>
                                                {
                                                    category?.map((category, index) => {
                                                        return (
                                                            <>
                                                                <option value={category.id}><b>{`${category.name}`}</b></option>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <span className="text-danger">{errors.user && 'Please select value.'}</span>
                                    </FormGroup>
                            }
                            <FormGroup>
                                <Label className="control-Label" htmlFor="role">
                                    Menu type
                                </Label>
                                <div className="mb-2">
                                    <select
                                        className="form-control"
                                        {...register('food_type', { required: true })}
                                        defaultValue={singleMenuJotai?.food_type || ''}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="veg">Veg</option>
                                        <option value="nonveg">Non-Veg</option>
                                    </select>
                                </div>
                                <span className="text-danger">{errors.food_type && 'Please select value.'}</span>
                            </FormGroup>
                            <FormGroup>
                                <Label className="control-Label" htmlFor="description">
                                    Description
                                </Label>

                                <Input type="textarea"
                                    {...register('description')}
                                    onChange={(event) => setDescription(event.target.value)}
                                    defaultValue={singleMenuJotai?.description}
                                    placeholder="Textarea Text" rows="3" />
                                {/* <span className="text-danger">{errors.description && 'description is required.'}</span> */}
                            </FormGroup>
                            <FormGroup>
                                <Switch onText="Active" onChange={() => setActive(singleMenuJotai?.active ? false : true)} offText="Deactive" defaultValue={singleMenuJotai?.active} />
                            </FormGroup>
                            <FormGroup>
                                {singleMenuJotai?.id ?
                                    <Button className="button btn-info" type="submit">
                                        Update
                                    </Button>
                                    :
                                    <Button className="button btn-info" type="submit">
                                        Submit
                                    </Button>
                                }
                            </FormGroup>
                        </Form>
                    </ComponentCard>
                </Col>
            </Row>
        </>
    );
};

export default MenuForm;


